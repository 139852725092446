import React, { useCallback, useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import { jwtDecode } from "jwt-decode";
import { Auth, UI } from "@lex/lex-types";
import useAuth from "../hooks/useAuth";
import useApi from "../hooks/useApi";
import { LoadingAndErrorsContext } from "./LoadingAndErrorsContext";

export const UserContext = React.createContext<UI.UserContextType>({
    decodedToken: undefined,
    login: async (username: string, password: string) => { return false },
    logout: () => { },
})

const decodeToken = (token: string): Auth.JwtToken | undefined => {
    if (!token) return undefined;
    try {
        const decoded = jwtDecode(token) as Auth.JwtToken;
        return decoded;
    } catch (error) {
        console.error('Error decoding JWT token:', error);
        return undefined;
    }
}

export const UserContextWrapper = ({ children }: { children: any }) => {
    const { addMessage } = useContext(LoadingAndErrorsContext);

    const { jwtToken, updateToken, clearToken } = useAuth();
    const { login: apiLogin, logout: apiLogout, refreshToken: apiRefreshToken } = useApi(addMessage);

    const [decodedToken, setDecodedToken] = useState<Auth.JwtToken | undefined>();

    const login = useCallback(async (username: string, password: string) => {
        const result = await apiLogin(username, password);
        if (result) {
            updateToken(result);
            return true;
        }
        clearToken();
        return false;
    }, [apiLogin, clearToken, updateToken])

    const logout = useCallback(async () => {
        await apiLogout();
        clearToken();
    }, [apiLogout, clearToken])

    const refreshToken = useCallback(async () => {
        const newToken = await apiRefreshToken();
        if (newToken) {
            updateToken(newToken)
        } else {
            clearToken();
        }
    }, [apiRefreshToken, clearToken, updateToken])

    useEffect(() => {
        const decoded = decodeToken(jwtToken);
        setDecodedToken(decoded);
    }, [jwtToken])

    useEffect(() => {
        if (decodedToken) {
            const timeToExpiration = dayjs(decodedToken.exp * 1000).diff(dayjs(), 'milliseconds');
            if (timeToExpiration < 0) {
                clearToken();
            }
            if (timeToExpiration <= 60 * 60 * 1000) {
                refreshToken();
            }
            if (timeToExpiration > 60 * 60 * 1000) {
                const refreshTimeout = setTimeout(() => {
                    refreshToken();
                }, timeToExpiration - 60 * 60 * 1000)
                return clearTimeout(refreshTimeout)
            }
        }
    }, [decodedToken, refreshToken, clearToken])

    return (
        <UserContext.Provider value={{
            decodedToken,
            login,
            logout,
        }}
        >
            {children}
        </UserContext.Provider>
    )
}
