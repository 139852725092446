import { useContext, useEffect, useState } from "react";
import { DB } from "@lex/lex-types";
import Draggable from "react-draggable";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import { BaseContext } from "../../context/BaseContext";
import SearchBox from "../search/SearchDocumentsBox";
import SearchResult from "../search/SearchResult";
import '../../assets/scss/add-link.scss';

const AddLink = ({
    value,
    onChange,
    onClose,
    onRemove,
}: {
    value?: string,
    onChange: (docId: string) => void,
    onClose: () => void,
    onRemove: () => void,
}) => {
    const { searchDocuments } = useContext(BaseContext);
    const [searchResults, setSearchResults] = useState<DB.SearchResponseEntry[]>([]);
    const wrapperRef = useOutsideClick(() => onClose());

    // TODO = loading state to show it's loading
    useEffect(() => {
        if (value) {
            const fetchResults = async () => {
                const results = await searchDocuments({ publishType: DB.DocumentSearchTypes.LIVE_ONLY, liveDocId: value.replace(/^view\//, '') }, 1);
                if (results) {
                    setSearchResults(results.docs);
                }
            }
            fetchResults();
        }
    }, [searchDocuments, value])

    return (
        <Draggable>
            <div className='lex-add-link-inner left nopadding' style={{ width: '40rem' }} ref={wrapperRef}>
                {
                    !value
                        ? <SearchBox
                            publishType={DB.DocumentSearchTypes.LIVE_ONLY}
                            onSearch={(results => setSearchResults(results))}
                            startWithAdvancedOpen
                        />
                        : null
                }
                <div className='lex-document-picker-results'>
                    {
                        searchResults.map(result =>
                            <SearchResult
                                {...result}
                                canOpen
                                onDelete={onRemove}
                                noConfirmDelete
                                onClick={() => onChange(result.docid)}
                            />
                        )
                    }
                </div>
            </div>
        </Draggable>
    )
}

export default AddLink;
