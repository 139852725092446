import { useCallback, useContext, useMemo } from "react";
import dayjs from 'dayjs';
import { DB, UI } from "@lex/lex-types";
import { BaseContext } from "../../context/BaseContext";
import useForms, { FormDataTemplate } from "../../hooks/useForms";

const CreateEmptyDocumentForm = ({
    onCreate,
    onClose,
}: {
    onCreate: (meta: DB.FrontendBackendMetaDraft) => void,
    onClose: () => void,
}) => {
    const { staticData } = useContext(BaseContext);

    const createDocumentFormData: FormDataTemplate = useMemo(() => ({
        name: 'create-document',
        content: [
            {
                type: UI.FormFieldTypes.SELECT,
                name: 'type_name',
                options: staticData.docTypes.sort(),
                label: 'Tipul documentului',
                placeholder: '...',
                value: 'LEGE',
                validate: (value: string) => !value ? 'Tipul documentului nu poate fi gol' : undefined,
            },
            {
                type: UI.FormFieldTypes.NUMBER,
                name: 'issuer_nr',
                label: 'Numarul documentului',
                placeholder: '...',
                value: 1,
                validate: (value: number) => !value || value <= 0 ? 'Numarul documentului trebuie sa fie un numar valid' : undefined,
            },
            {
                type: UI.FormFieldTypes.DATE,
                name: 'issue_date',
                label: 'Data emiterii documentului',
                placeholder: '...',
                value: dayjs().format('YYYY-MM-DD'),
                validate: (value: string) => !/\d{4}-\d{2}-\d{2}/.test(value) ? 'Data emiterii trebuie sa fie de forma YYYY-MM-DD' : undefined,
                separatorBelow: true
            },
            {
                type: UI.FormFieldTypes.SELECT,
                name: 'issuer_name',
                options: staticData.issuers.sort(),
                label: 'Emitent',
                placeholder: '...',
                value: '',
                validate: (value: string) => !value ? 'Emitentul documentului nu poate fi gol' : undefined,
            },
            {
                type: UI.FormFieldTypes.NUMBER,
                name: 'publish_nr',
                label: 'Numarul Monitorului Oficial',
                placeholder: '...',
                value: 1,
                validate: (value: number) => !value || value <= 0 ? 'Numarul monitorului oficial trebuie sa fie un numar valid' : undefined,
            },
            {
                type: UI.FormFieldTypes.DATE,
                name: 'publish_date',
                label: 'Data Monitorului Oficial',
                placeholder: '...',
                value: dayjs().format('YYYY-MM-DD'),
                validate: (value: string) => !/\d{4}-\d{2}-\d{2}/.test(value) ? 'Data monitorului trebuie sa fie de forma YYYY-MM-DD' : undefined,
                separatorBelow: true
            },
            {
                type: UI.FormFieldTypes.SELECT,
                name: 'status_name',
                options: Object.keys(staticData.docStatuses).sort(),
                label: 'Starea documentului',
                direction: 'up',
                placeholder: '...',
                value: 'Vigoare',
            },
            {
                type: UI.FormFieldTypes.STRING,
                name: 'title',
                label: 'Titlul documentului',
                placeholder: '...',
                value: 'Document nou',
                validate: (value: string) => !value.trim() ? 'Titlul documentului nu poate fi gol' : undefined,
            },
            {
                type: UI.FormFieldTypes.SELECT,
                name: 'domains',
                direction: 'up',
                options: staticData.domains.sort(),
                label: 'Domeniul',
                placeholder: '...',
                value: '',
                validate: (value: string) => !value.trim() ? 'Domeniul documentului nu poate fi gol' : undefined,
            },
        ]
    }), [staticData.docTypes, staticData.domains, staticData.issuers, staticData.docStatuses])

    const { renderForm, getValuesAsObject, isValid } = useForms([createDocumentFormData]);

    const handleFinish = useCallback(() => {
        if (!isValid) {
            return;
        }
        const meta = getValuesAsObject('create-document', ['type_name', 'issuer_nr', 'issue_date', 'issuer_name', 'publish_nr', 'publish_date', 'status_name', 'title', 'domains', 'owner']);

        if (typeof meta.domains === 'string') {
            meta.domains = [meta.domains]
        }
        onCreate(meta as DB.FrontendBackendMetaDraft);
    }, [getValuesAsObject, isValid, onCreate]);

    return (
        <div className='upload-panel'>
            <div className='upload-panel-title'>
                Document nou
            </div>
            <div className='upload-panel-body'>
                {renderForm('create-document')}
            </div>
            <div className='upload-panel-actions' style={{ gap: '0.5rem' }}>
                <div
                    className={`lex-button ${isValid ? 'success' : 'disabled'}`}
                    onClick={handleFinish}
                    onKeyDown={e => {
                        if (e.key === 'Enter' || e.key === ' ') {
                            handleFinish();
                        }
                    }}
                    tabIndex={createDocumentFormData.content.length + 1}
                >
                    Creaza
                </div>
                <div
                    className='lex-button neutral'
                    onClick={onClose}
                    onKeyDown={e => {
                        if (e.key === 'Enter' || e.key === ' ') {
                            onClose();
                        }
                    }}
                    tabIndex={createDocumentFormData.content.length + 2}
                >
                    Anuleaza
                </div>
            </div>
        </div>
    )
}

export default CreateEmptyDocumentForm;
