import { useEffect, createRef, useCallback, useContext, useMemo, useState } from "react";
import { DB } from "@lex/lex-types"
import * as Icons from 'react-bootstrap-icons';
import { Form } from "react-bootstrap";
import { BaseContext } from "../../context/BaseContext";
import { LoadingAndErrorsContext } from "../../context/LoadingAndErrorsContext";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import '../../assets/scss/searchbox.scss';

const variationToRealType = (type: string, typesVariationMap: Record<string, string[]>) => {
    if (!type) return undefined
    for (const realType of Object.keys(typesVariationMap)) {
        if (realType.toLowerCase() === type.toLowerCase()) {
            return realType;
        }
        if (typesVariationMap[realType].map(t => t.toLowerCase()).includes(type.toLowerCase())) {
            return realType;
        }
    }
    return undefined;
}

const simplifiedSearchTextToSearchParams = (searchText: string, types: string[], typesVariationMap: Record<string, string[]>) => {
    const searchParams: DB.SearchRequestParams = {};
    const allTypeKeywords = [...types.map(t => t.toLowerCase())];
    types.forEach(t => {
        if (typesVariationMap[t]) {
            allTypeKeywords.push(...typesVariationMap[t].map(t => t.toLowerCase()))
        }
    })

    const tipRex = new RegExp(`(?:^| )(${allTypeKeywords.join('|').replace(/\./g, '\\.')})?(?:\\s*)((?:nr|nr\\.|numarul|numaru)?(?:\\s*)\\d*)?(?:[\\/ ]?)(\\d{4})?(?=$| )`, 'gi')
    const normalizedSearchText = (searchText || '').replace(/ +/g, ' ').toLowerCase();

    const keywordMatch = tipRex.exec(normalizedSearchText);

    if (keywordMatch) {
        const [, type, , number, year] = keywordMatch;

        const realType = variationToRealType(type, typesVariationMap);

        if (realType) {
            searchParams.type = realType;
        }
        if (number) {
            searchParams.issuer_nr = parseInt(number, 10);
        }
        if (year) {
            searchParams.year = parseInt(year, 10);
        }
    }
    return searchParams;
}

const simplifiedSearchParamsToSearchText = (searchParams: DB.SearchRequestParams) => {
    let searchText = '';
    if (searchParams.type) {
        searchText = `${searchParams.type}`;
    }
    if (searchParams.issuer_nr) {
        searchText += ` ${searchParams.issuer_nr}`;
    }
    if (searchParams.year) {
        searchText += `/${searchParams.year}`;
    }

    return searchText;
}

const SearchDocumentsBox = ({
    placeholder,
    publishType = DB.DocumentSearchTypes.ALL,
    onSearch,
    startWithAdvancedOpen = false,
}: {
    placeholder?: string,
    publishType?: DB.DocumentSearchTypes,
    onSearch: (results: DB.SearchResponseEntry[]) => void,
    startWithAdvancedOpen?: boolean,
}) => {
    const { staticData, searchDocuments } = useContext(BaseContext);

    const { addMessage } = useContext(LoadingAndErrorsContext);
    const [searchParams, setSearchParams] = useState<DB.SearchRequestParams>({ title: '', publishType: publishType });
    const [searchText, setSearchText] = useState('');
    const [isOpen, setIsOpen] = useState(startWithAdvancedOpen);

    const wrapperRef = useOutsideClick(() => {
        if (isOpen) setIsOpen(false)
    });

    const searchRef = createRef<HTMLInputElement>();

    const canSearch = useMemo(() => {
        if (Object.keys(searchParams).length) {
            return Object.keys(searchParams).some(key => (searchParams as any)[key])
        }
        return false;
    }, [searchParams])

    const onKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
        if (e.key === 'Enter') {
            performSearch(true);
        }
    }

    const handleSearchParamsUpdate = useCallback((key: keyof DB.SearchRequestParams, value: string) => {
        let processedValue: string | number | undefined = value;
        switch (key) {
            case 'issuer_nr':
            case 'year':
            case 'nr_mof':
            case 'year_mof':
            case 'size':
                processedValue = value ? parseInt(value, 10) : undefined;
                break;
            default:
                break;
        }
        const updatedSearchParams = { ...searchParams, [key]: processedValue }
        setSearchParams(updatedSearchParams);
        setSearchText(simplifiedSearchParamsToSearchText(updatedSearchParams));
    }, [searchParams])

    const performSearch = useCallback(async (force = false) => {

        if (force || canSearch) {
            setIsOpen(false);
            const results = await searchDocuments(searchParams, 10, true);
            if (results) {
                onSearch(results.docs);
            } else {
                addMessage({ type: 'error', errorMessage: 'An error has occured during search', text: 'Search error' })
            }
        }
    }, [addMessage, canSearch, onSearch, searchDocuments, searchParams]);

    useEffect(() => {
        if (searchRef.current) {
            searchRef.current?.focus();
        }
    }, [])

    return (
        <div className='lex-search' ref={wrapperRef}>
            <div className={`lex-search-text form-control ${isOpen ? 'open' : ''}`}>
                <Form.Control
                    ref={searchRef}
                    type='text'
                    name='textSearch'
                    placeholder={placeholder || 'Cauta documente...'}
                    value={searchText}
                    onKeyDown={(e) => onKeyDown(e)}
                    onChange={(e) => { setSearchText(e.target.value); setSearchParams(simplifiedSearchTextToSearchParams(e.target.value, staticData.docTypes, staticData.typesVariationMap)) }}
                    onClick={() => setIsOpen(true)}
                    autoComplete='off'
                    tabIndex={401}
                />
                <Icons.Search className='lex-search-icon' />
            </div>
            {
                isOpen
                    ? <div className='lex-advanced-search'>
                        <div className='lex-search-row'>
                            <div className='lex-search-label'>
                                Numar document
                            </div>
                            <Form.Control
                                type='number'
                                name='issuer_nr'
                                value={searchParams.issuer_nr}
                                onChange={(e) => handleSearchParamsUpdate('issuer_nr', e.target.value)}
                                onKeyDown={(e) => onKeyDown(e)}
                                autoComplete='off'
                                tabIndex={402}
                            />
                        </div>
                        <div className='lex-search-row'>
                            <div className='lex-search-label'>
                                An document
                            </div>
                            <Form.Control
                                type='number'
                                name='year'
                                value={searchParams.year}
                                onChange={(e) => handleSearchParamsUpdate('year', e.target.value)}
                                onKeyDown={(e) => onKeyDown(e)}
                                autoComplete='off'
                                tabIndex={403}
                            />
                        </div>
                        <div className='lex-search-row'>
                            <div className='lex-search-label'>
                                Numar monitor
                            </div>
                            <Form.Control
                                type='number'
                                name='nr_mof'
                                value={searchParams.nr_mof}
                                onChange={(e) => handleSearchParamsUpdate('nr_mof', e.target.value)}
                                onKeyDown={(e) => onKeyDown(e)}
                                autoComplete='off'
                                tabIndex={404}
                            />
                        </div>
                        <div className='lex-search-row'>
                            <div className='lex-search-label'>
                                An monitor
                            </div>
                            <Form.Control
                                type='number'
                                name='year_mof'
                                value={searchParams.year_mof}
                                onChange={(e) => handleSearchParamsUpdate('year_mof', e.target.value)}
                                onKeyDown={(e) => onKeyDown(e)}
                                autoComplete='off'
                                tabIndex={405}
                            />
                        </div>
                        <div className='lex-search-row'>
                            <div className='lex-search-label'>
                                Emitent
                            </div>
                            <Form.Select
                                name="issuer"
                                aria-label='lex-issuer'
                                className='form-control'
                                value={searchParams.issuer || ''}
                                onChange={(e) => handleSearchParamsUpdate('issuer', e.target.value)}
                                tabIndex={406}
                            >
                                <option value=''>Selecteaza emitentul</option>
                                {
                                    staticData.issuers.map((i, idx) => <option value={i} key={idx}>{i}</option>)
                                }
                            </Form.Select>
                        </div>
                        <div className='lex-search-row'>
                            <div className='lex-search-label'>
                                Domeniu
                            </div>
                            <Form.Select
                                name="domain"
                                aria-label='lex-domain'
                                className='form-control'
                                value={searchParams.domain || ''}
                                onChange={(e) => handleSearchParamsUpdate('domain', e.target.value)}
                                tabIndex={407}
                            >
                                <option value={''}>Selecteaza domeniul</option>
                                {
                                    staticData.domains.map((i, idx) => <option value={i} key={idx}>{i}</option>)
                                }
                            </Form.Select>
                        </div>
                        <div className='lex-search-row'>
                            <div className='lex-search-label'>
                                Tip
                            </div>
                            <Form.Select
                                name="type"
                                aria-label='lex-type'
                                className='form-control'
                                value={searchParams.type || ''}
                                onChange={(e) => handleSearchParamsUpdate('type', e.target.value)}
                                tabIndex={408}
                            >
                                <option value={''}>Selecteaza tipul documentului</option>
                                {
                                    staticData.docTypes.map((i, idx) => <option value={i} key={idx}>{i}</option>)
                                }
                            </Form.Select>
                        </div>
                        {
                            publishType === DB.DocumentSearchTypes.ALL
                                ? <div className='lex-search-row'>
                                    <div className='lex-search-label'>
                                        Status publicare
                                    </div>
                                    <Form.Select
                                        name="type"
                                        aria-label='lex-type'
                                        className='form-control'
                                        value={searchParams.publishType}
                                        onChange={(e) => setSearchParams(prev => ({ ...prev, publishType: (e.target.value as DB.DocumentSearchTypes) || undefined }))}
                                        style={{ textTransform: 'capitalize' }}
                                        tabIndex={409}
                                    >
                                        {
                                            Object.values(DB.DocumentSearchTypes).map((i, idx) =>
                                                <option value={i} key={idx} >{i.replace(/-|_/g, ' ').toLowerCase()}</option>
                                            )
                                        }
                                    </Form.Select>
                                </div>
                                : null
                        }
                        <div className='lex-search-actions-row'>
                            <div
                                className={`lex-button success ${!canSearch ? 'disabled' : ''}`}
                                onClick={() => performSearch()}
                                tabIndex={410}
                            >
                                Cauta
                            </div>
                            <div
                                className='lex-button danger'
                                onClick={() => setIsOpen(false)}
                                tabIndex={411}
                            >
                                Inchide
                            </div>
                        </div>
                    </div>
                    : null
            }
        </div>
    )
}

export default SearchDocumentsBox;
